import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { css } from '@emotion/react';

import BaseForm from '~/components/common/Form/BaseForm';
import Title from '~/components/common/DialogTitle';
import Spinner from '~/components/ui/Spinner';
import ScrollArea from '~/eris-ui/eris-components/components/ScrollArea';
import { DIALOG_MAX_HEIGHT } from '~/constants';

import NormalFormItems from './NormalFormItems';
import type { NormalFormProps } from './type';

const NormalForm = (props: NormalFormProps) => {
  const {
    title,
    defaultValues,
    schema,
    mode,
    reValidateMode,
    onClose,
    maxWidth = '1920px',
    minWidth = '360px',
    width = 'auto',
    context,
    onSubmit,
    onSubmitError,
    loading = false,
    ...other
  } = props;

  const formRef = useRef<{ submit: () => Promise<any> }>(null);

  // ScrollArea 内部结构，需要覆盖样式
  const scrollAreaClass = css({
    ['& > div > div']: {
      display: 'block !important',
    },
  });
  return (
    <Box sx={{ maxWidth, minWidth, width }} className="PNormalForm" data-e2e={title}>
      <ScrollArea maxHeight={`calc(100vh - ${DIALOG_MAX_HEIGHT}px)`} css={scrollAreaClass}>
        <Title onClose={onClose}>{title}</Title>
        {loading ? (
          <Spinner>
            <Box height="458px"></Box>
          </Spinner>
        ) : (
          <BaseForm
            reValidateMode={reValidateMode}
            ref={formRef}
            mode={mode}
            defaultValues={defaultValues}
            schema={schema}
            context={context}
            onSubmit={onSubmit}
            onSubmitError={onSubmitError}
          >
            <NormalFormItems onClose={onClose} onSubmit={onSubmit} {...other}></NormalFormItems>
          </BaseForm>
        )}
      </ScrollArea>
    </Box>
  );
};

export default NormalForm;
