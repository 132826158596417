import { css } from '@emotion/react';

import themeCSSObj from '~/styles/theme.module.scss';
import { ButtonClasses } from '~/components/ui/Button/lib';

import formCSSObj from './FullFormItems.module.scss';

export const backdropCSS = css({
  display: 'flex',
  height: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  background: themeCSSObj.appFillColor1,
  overflow: 'auto',
  paddingBottom: formCSSObj.footerHeight,
});

// eslint-disable-next-line no-undef
export const getContentCSS = (Summary: ((props: any) => React.ReactElement) | undefined) =>
  css({
    width: Summary ? '95%' : '90%',
    minWidth: Summary ? '1024px' : '1012px',
    maxWidth: Summary ? '1520px' : '1296px',
  });

// eslint-disable-next-line no-undef
export const getLeftCSS = (Summary: ((props: any) => React.ReactElement) | undefined) =>
  css({
    height: '100%',
    flex: Summary ? 2 : 1,
    minWidth: Summary ? 0 : '6px',
  });

// eslint-disable-next-line no-undef
export const getRightCSS = (Summary: ((props: any) => React.ReactElement) | undefined) =>
  css({
    height: '100%',
    flex: 1,
    minWidth: Summary ? 0 : '6px',
  });

export const submitBtnCSS = css({
  [`& .${ButtonClasses['root']}`]: {
    padding: '6px 24px',
  },
});
