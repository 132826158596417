import { Box } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import intl from 'react-intl-universal';

import { ButtonProps } from '~/components/ui/Button/type';
import Button from '~/components/ui/Button';
import FunctionalButton from '~/components/common/FunctionalButton';

import { SubmitState } from './type';

function NormalFormItems(props: {
  onSubmit?: (data: any, e?: React.BaseSyntheticEvent) => void | Promise<any>;
  actionText?: string;
  actionDesc?: React.ReactElement;
  btnProps?: ButtonProps; //自定义表单提交按钮
  maxWidth?: string;
  minWidth?: string;
  width?: string;
  children?: React.ReactNode;
  hideFooter?: boolean;
  showCancel?: boolean;
  footerRender?: (props: SubmitState) => React.ReactNode;
  onClose: () => void;
}) {
  const {
    actionText,
    actionDesc,
    btnProps,
    hideFooter,
    children,
    showCancel,
    onClose,
    footerRender,
    onSubmit,
  } = props;

  const {
    handleSubmit,
    formState: { isSubmitting: loading },
  } = useFormContext();

  return (
    <>
      <Box sx={{ padding: '0 32px' }}>{children}</Box>
      {hideFooter ? null : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '24px 32px',
          }}
        >
          {footerRender && onSubmit
            ? footerRender({ loading: loading, trySubmit: handleSubmit(onSubmit) })
            : onSubmit && (
                <>
                  <Box display="flex" flexDirection="row-reverse">
                    <Button
                      css={{ marginLeft: showCancel ? '8px !important' : undefined }}
                      type="submit"
                      data-e2e="Submit"
                      loading={loading}
                      {...btnProps}
                    >
                      {actionText}
                    </Button>
                    {actionDesc && (
                      <Box css={{ display: 'flex', alignItems: 'center' }}>{actionDesc}</Box>
                    )}
                    {showCancel && (
                      <FunctionalButton
                        mode="cancel"
                        onClick={onClose}
                        css={{ alignSelf: 'center' }}
                      >
                        {intl.get('取消')}
                      </FunctionalButton>
                    )}
                  </Box>
                </>
              )}
        </Box>
      )}
    </>
  );
}

export default NormalFormItems;
