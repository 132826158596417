import React, { forwardRef } from 'react';
import intl from 'react-intl-universal';

import FullForm from './FullForm';
import NormalForm from './NormalForm';
import BaseForm from './BaseForm';
import { FormProps } from './type';

const _Form = (props: FormProps, ref: React.MutableRefObject<any>) => {
  const { type } = props;
  if (type === 'fullForm') {
    return <FullForm {...props}></FullForm>;
  } else if (type === 'normalForm') {
    const { actionText = intl.get('创建'), ...other } = props;
    return <NormalForm actionText={actionText} {...other}></NormalForm>;
  } else if (type === 'baseForm') {
    return <BaseForm ref={ref} {...props}></BaseForm>;
  } else {
    return null;
  }
};

const Form = forwardRef(_Form);

export default Form;
