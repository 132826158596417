import React, { memo, useRef } from 'react';
import clsx from 'clsx';

import BaseForm from '~/components/common/Form/BaseForm';

import { FullFormProps } from './type';
import FullFormItems from './FullFormItems';

const FullForm = memo((props: FullFormProps) => {
  const {
    defaultValues,
    schema,
    title,
    mode,
    reValidateMode,
    onClose,
    onSubmit,
    onSubmitError,
    Summary,
    footerRender,
    children,
    actionText,
    actionDesc,
    btnProps,
    hideFooter,
    showCancel,
    className,
    context,
    titleRight
  } = props;

  const formRef = useRef<{ submit: () => Promise<void> }>(null);

  return (
    <BaseForm
      ref={formRef}
      mode={mode}
      reValidateMode={reValidateMode}
      schema={schema}
      defaultValues={defaultValues}
      className={clsx(className)}
      context={context}
      onSubmit={onSubmit}
      onSubmitError={onSubmitError}
    >
      <FullFormItems
        onSubmit={onSubmit}
        title={title}
        titleRight={titleRight}
        onClose={onClose}
        Summary={Summary}
        footerRender={footerRender}
        hideFooter={hideFooter}
        actionText={actionText}
        actionDesc={actionDesc}
        btnProps={btnProps}
        showCancel={showCancel}
      >
        {children}
      </FullFormItems>
    </BaseForm>
  );
});

export default FullForm;
