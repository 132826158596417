import React from 'react';
import { Box } from '@mui/material';
import intl from 'react-intl-universal';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import Button from '~/components/ui/Button';
import IconButton from '~/components/ui/IconButton';
import Typography from '~/components/ui/Typography';
import themeCSSObj from '~/styles/theme.module.scss';
import FunctionalButton from '~/components/common/FunctionalButton';
import ScrollArea from '~/eris-ui/eris-components/components/ScrollArea';

import styles from './FullFormItems.module.scss';
import { FullFormItemsProps } from './type';
import { backdropCSS, getContentCSS, getLeftCSS, getRightCSS, submitBtnCSS } from './lib';

const FullFormItems = (props: FullFormItemsProps) => {
  const {
    title,
    onClose,
    children,
    onSubmit,
    Summary,
    footerRender,
    actionText = intl.get('创建'),
    actionDesc,
    btnProps,
    hideFooter,
    showCancel = false,
    titleRight
  } = props;

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useFormContext();

  const contentCSS = getContentCSS(Summary);
  const leftCSS = getLeftCSS(Summary);
  const rightCSS = getRightCSS(Summary);

  return (
    <Box css={backdropCSS} className="PFullForm">
      <Box css={leftCSS} className="PFullForm-spaceLeft"></Box>
      <Box
        css={Summary ? [contentCSS, { height: '100%' }] : contentCSS}
        className={clsx('PFullForm-wrapper', styles['wrapper'])}
      >
        <Box className={styles['content']}>
          <Box
            className={clsx(styles['content-left'], 'PFullForm-left')}
            css={{ overflow: Summary ? 'auto' : 'hidden' }}
          >
            <ScrollArea height="100%">
              <Box className={styles['header']} css={{ paddingRight: '36px' }}>
                <Box
                  css={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    height: '100%',
                    paddingLeft: Summary ? '20px' : '8px',
                  }}
                >
                  <IconButton icon="ArrowLeft20" tooltip={intl.get('返回')} onClick={onClose} />
                  <Typography
                    size="s3"
                    type="t1"
                    css={{ display: 'inline-block', marginLeft: '8px' }}
                    fontWeight={+themeCSSObj.typographyFontWeightMedium}
                  >
                    {title}
                  </Typography>
                </Box>
                {titleRight && titleRight}
              </Box>
              <Box
                css={{
                  paddingLeft: Summary ? '48px' : '36px',
                  paddingRight: Summary ? 0 : '36px',
                  paddingBottom: '16px',
                }}
              >
                {children}
              </Box>
            </ScrollArea>
          </Box>
          {Summary && (
            <ScrollArea
              disabledHorizontal
              height="calc(100% - 56px)"
              css={{
                margin: '60px 24px 0 16px',
              }}
            >
              <Box className={styles['content-right']}>
                <Summary formControl={control} />
              </Box>
            </ScrollArea>
          )}
        </Box>
      </Box>
      <Box css={rightCSS} className="PFullForm-spaceRight"></Box>
      {hideFooter ? null : (
        <Box className={styles['footer']}>
          <Box css={leftCSS}></Box>
          <Box
            css={[
              contentCSS,
              { paddingRight: Summary ? '296px' : '36px', paddingLeft: Summary ? '48px' : '36px' },
            ]}
          >
            {footerRender ? (
              footerRender({ loading: isSubmitting, trySubmit: handleSubmit(onSubmit) })
            ) : (
              <Box display="flex" flexDirection="row-reverse">
                <Button
                  css={[submitBtnCSS, { marginLeft: showCancel ? '8px !important' : undefined }]}
                  className={styles['footer-btn']}
                  type="submit"
                  data-e2e="Submit"
                  loading={isSubmitting}
                  {...btnProps}
                >
                  {actionText}
                </Button>
                {actionDesc && (
                  <Box css={{ display: 'flex', alignItems: 'center' }}>{actionDesc}</Box>
                )}
                {showCancel && (
                  <FunctionalButton mode="cancel" onClick={onClose} css={{ alignSelf: 'center' }}>
                    {intl.get('取消')}
                  </FunctionalButton>
                )}
              </Box>
            )}
          </Box>
          <Box css={rightCSS}></Box>
        </Box>
      )}
    </Box>
  );
};

export default FullFormItems;
